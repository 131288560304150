import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

// lazy load all the views

// auth
const Login2 = React.lazy(() => import('../pages/account2/Login2'));
const Logout2 = React.lazy(() => import('../pages/account2/Logout2'));
const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));

// dashboard
const EcommerceDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce'));
const ChangePassword = React.lazy(() => import('../pages/dashboard/ChangePassword'));

// Multiple Block
const MultipleBlockList = React.lazy(() => import('../pages/MultipleBlock/List'));
const MultipleBlockCreate = React.lazy(() => import('../pages/MultipleBlock/Create'));

// Multiple Block
const JobTypeList = React.lazy(() => import('../pages/JobType/List'));
const JobTypeCreate = React.lazy(() => import('../pages/JobType/Create'));

//Educations
const EducationList = React.lazy(() => import('../pages/Education/List'));
const EducationCreate = React.lazy(() => import('../pages/Education/Create'));

//Countries
const CountriesList = React.lazy(() => import('../pages/Countries/List'));
const CountriesCreate = React.lazy(() => import('../pages/Countries/Create'));

//States
const StatesList = React.lazy(() => import('../pages/States/List'));
const StatesCreate = React.lazy(() => import('../pages/States/Create'));

// Employer
const EmployerList = React.lazy(() => import('../pages/Employer/List'));
const EmployerProfile = React.lazy(() => import('../pages/Employer/Profile'));

//Candidate
const CandidateList = React.lazy(() => import('../pages/Candidate/List'));
const CandidateProfile = React.lazy(() => import('../pages/Candidate/Profile'));

// Job Descriptions Template
const JobDescriptionTemplateList = React.lazy(() => import('../pages/JobDescriptionTemplate/List'));
const JobDescriptionTemplateCreate = React.lazy(() => import('../pages/JobDescriptionTemplate/Create'));

// Packages
const PackageList = React.lazy(() => import('../pages/Package/List'));
const PackageCreate = React.lazy(() => import('../pages/Package/Create'));

//Jobs
const JobList = React.lazy(() => import('../pages/Job/List'));
const JobShow = React.lazy(() => import('../pages/Job/Show'));

const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));


const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: '/',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login2} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'logout', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: '*',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {path: 'dashboard', element: <LoadComponent component={EcommerceDashboard} />},
                {path: 'change-password', element: <LoadComponent component={ChangePassword} />},

                {path: 'multiple-block', element: <LoadComponent component={MultipleBlockList} />},
                {path: 'multiple-block/create', element: <LoadComponent component={MultipleBlockCreate} />},
                {path: 'multiple-block/edit/:slug', element: <LoadComponent component={MultipleBlockCreate} />},

                {path: 'employer', element: <LoadComponent component={EmployerList} />},
                {path: 'employer/profile/:slug', element: <LoadComponent component={EmployerProfile} />},

                {path: 'candidate', element: <LoadComponent component={CandidateList} />},
                {path: 'candidate/profile/:slug', element: <LoadComponent component={CandidateProfile} />},

                {path: 'job-type', element: <LoadComponent component={JobTypeList} />},
                {path: 'job-type/create', element: <LoadComponent component={JobTypeCreate} />},
                {path: 'job-type/edit/:slug', element: <LoadComponent component={JobTypeCreate} />},

                {path: 'education', element: <LoadComponent component={EducationList} />},
                {path: 'education/create', element: <LoadComponent component={EducationCreate} />},
                {path: 'education/edit/:slug', element: <LoadComponent component={EducationCreate} />},

                {path: 'countries', element: <LoadComponent component={CountriesList} />},
                {path: 'countries/create', element: <LoadComponent component={CountriesCreate} />},
                {path: 'countries/edit/:slug', element: <LoadComponent component={CountriesCreate} />},

                {path: 'states', element: <LoadComponent component={StatesList} />},
                {path: 'states/create', element: <LoadComponent component={StatesCreate} />},
                {path: 'states/edit/:slug', element: <LoadComponent component={StatesCreate} />},

                {path: 'job-description-template', element: <LoadComponent component={JobDescriptionTemplateList} />},
                {path: 'job-description-template/create', element: <LoadComponent component={JobDescriptionTemplateCreate} />},
                {path: 'job-description-template/edit/:slug', element: <LoadComponent component={JobDescriptionTemplateCreate} />},

                {path: 'package', element: <LoadComponent component={PackageList} />},
                {path: 'package/create', element: <LoadComponent component={PackageCreate} />},
                {path: 'package/edit/:slug', element: <LoadComponent component={PackageCreate} />},

                {path: 'jobs', element: <LoadComponent component={JobList} />},
                {path: 'jobs/show/:slug', element: <LoadComponent component={JobShow} />},
            ],
        },
    ]);
};

export { AllRoutes };
