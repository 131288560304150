const isProd = true;
var config = {};
if (isProd) {
    config = {
        APP_NAME: "eZaango Jobs",
        API_URL: "https://api.ezaangojobs.com/",
    };
} else {
    config = {
        APP_NAME: "eZaango Jobs",
        API_URL: "http://api.ezaangojobs/",
    };
}


export default config;
